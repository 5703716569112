.App {
  text-align: center;
}

.heading{
  text-align:center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title{
  width:700px;
  text-align: center;
  margin: 0 auto;
  margin-top:100px;
  margin-bottom: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print{
    @page {
        margin: 0;
        size:auto;
    }
    *{ 
        visibility:hidden;
        position:unset;
    }


    #myCanvas {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        margin-top: 2cm;
        margin-bottom: 2cm;
        margin-left: 2cm;
        margin: 1cm;
        margin-right: 2cm;
        width: 100vw;
    }

    .container {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }

    .item {
      width: 100vw;
    }

    #canvas-wrapper{

      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }

    .container::-webkit-scrollbar {
      height: 8px;
    }
    
    .container::-webkit-scrollbar-thumb {
      background-color: gray;
      border-radius: 10px;
    }
    
    .container::-webkit-scrollbar-track {
      background-color: green;
      border-radius: 10px;
    }


    .resource{
      text-align: left;
      float: left;
    }

    .App > h2{
      text-align: center;
    }
    

}
.watermark {
    display:flex;
    opacity: .9;
    z-index:2;
    text-align:center;
}


.banner{
  position:fixed;
  top:0;
  width:100%;
  height:100vh;
  background: white;
  display: none;
  z-index: 999999999;
}
.banner {
  padding-top:50vw;
}
.banner > *{
  font-weight: 300;
  font-size: 32px;
  text-align: center;
}

@media screen and (max-width:1000px){
  #search{
    width:20%!important;
  }
  .searchWrp >*{
    margin-left: 10px!important;
  } 
  .searchWrp:first-child{
    margin-left:0!important;
  }

}



@media screen and (orientation:portrait) {

    .banner{
      display: block;
    }
    #root{
      display: none;
    }
    
   
}

.menu-toggle.left div{
  width: 30px;
  height: 4px;
  background-color: white;
  margin: 6px 0;
}
html{
  min-height: 100vh;
}
 .fixed{
   z-index:9;
   
 }
